import React, { FC } from 'react';
import { FileBaseFragment } from '@/controllers/graphql/generated';
import { typography } from '@/components/ui/typography';
import { LogoItem } from '@/components/landing/LogoItem';
import { cn } from '@/lib';
import styles from './LogoItemsListBlock.module.scss';

interface Item {
  id: number;
  name: string;
  logo?: FileBaseFragment | null;
}

interface Props {
  title?: string;
  moreItemsText?: string;
  items: Item[];
}

export const LogoItemsListBlock: FC<Props> = (props) => {
  const {
    title,
    moreItemsText,
    items,
  } = props;

  return (
    <>
      {
        title && (
          <div className="grid-x align-center">
            <div
              className="text-center large-6 medium-8 mb-80 small-mb-40"
            >
              <h2
                data-qa="logo-items-list-title"
                className={typography.landingH2}
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            </div>
          </div>
        )
      }

      <div className={cn(styles.rows, 'grid-x grid-margin-x mb-48 small-mb-40')} data-qa="companies-list-block">
        {items.map((item) => (
          <div
            key={item.id}
            className="cell large-3 medium-4 small-6"
          >
            <LogoItem
              src={item.logo?.url ?? ''}
              title={item.name}
              height={48}
              width={166}
            />
          </div>
        ))}
      </div>

      {
        moreItemsText && (
          <div className="grid-x align-center">
            <div className="text-center large-8 medium-8">
              <p
                data-qa="logo-list-more-items-text"
                className={typography.landingTextMain}
              >
                {moreItemsText}
              </p>
            </div>
          </div>
        )
      }
    </>
  );
};
